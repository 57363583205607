// Gets the URL for the current environment
export default function getAbsoluteURL(path) {
  let baseURL = 'http://localhost:8888';

  if (process.env.NODE_ENV === 'production') {
    baseURL = `https://${process.env.HOST_NAME}`;
  }

  return baseURL + path;
}
